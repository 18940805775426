#contact{
    margin-bottom: 5rem;
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
}

.contact-container > h2{
    margin-bottom: 0;
}

.form-group{
    width: 100%;
    border-radius: 0.8rem;
    transition: var(--transition);
}

.form-group:hover{
    opacity: 0.8;
}

/* form */

legend{
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 1.2rem;
}

fieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
}

form button{
    font-weight: bold;
    justify-self: center;
    font-size: 1rem;
    padding: 0.8rem 1.4rem !important;
}

input, textarea {
    font-family: inherit;
    width: 100%;
    padding: 1rem;
    border-radius: 0.8rem;
    border: 2px solid var(--color-primary);
    transition: var(--transition);
    background: transparent;
    resize: none;
    color: var(--color-white);
}

input:focus, textarea:focus, input:hover, textarea:hover{
    outline: none;
    border: 2px solid var(--color-primary-variant);
}

/* media queries */

@media screen and (max-width: 1000px){
    fieldset{
        gap: 1.2rem;
    }
}