footer{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    background-color: var(--color-primary-variant);
}

.container-left, .container-right{
    flex: 1 0 auto;
    text-align: center;
    margin: 0 0.8rem;
}

.container-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
}

.container-right > h2 {
    margin-bottom: 0;
}

.container-right > img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
}

.container-left > .header-socials {
    justify-content: flex-start;
}

.container-left > .header-socials > a > svg{
    width: 2.5rem;
    height: 2.5rem;
}

/* media queries */

@media screen and (max-width: 1000px){
    .container-left > .header-socials {
        gap: 0.7rem;
    }

    .container-right > h2 {
        font-size: 1.1rem;
    }

    footer {
        padding: 1.3rem;
    }
}

@media screen and (max-width: 600px) {
    footer{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 7rem;
    }
}