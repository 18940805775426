h1 {
    text-align: center;
    margin-bottom: 2rem;
}

.aboutme > h2, .aboutme > h4 {
    margin-bottom: 2rem;
}

#about {
    margin-top: 8rem;
}

.about-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8%;
}

.aboutme > h2{
    color: var(--color-primary);
}

.aboutme{
    margin: 2rem auto 4rem;
}

/* picture */

.asher > img{
    flex: 1;
    width: 20rem;
    border-radius: 50%;
    border: 0.5rem solid var(--color-primary);
    box-shadow: 0 0 0.5rem var(--color-primary);
    margin: 0 auto;
    display: block;
}

/* media queries */

@media screen and (max-width: 1000px) {
    .about-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .aboutme{
        text-align: center;
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .aboutme{
        text-align: center;
        width: 65%;
    }
    
    #about h1{
        margin-top: -4rem;
    }
}