.skill-container > img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.skill-container{
    transition: var(--transition);
}


.skill-container:hover {
    transform: rotate(7deg);
}

.skill-container > h2{
    color: var(--color-primary);
    margin-bottom: -0.5rem;
}

hr{
    margin: 1rem 0;
    max-width: 80%;
    border-top: 1px solid #ccc;
    overflow: visible;
    display: block;
    text-align: center;
    height: 0;
}

p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0;
    display: block;
}

.column{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem 5rem;
}

/* media queries */

@media screen and (max-width: 1000px) {
    .column{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .column{
        grid-template-columns: repeat(1, 1fr);
    }

    .skill-container{
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .skill-container > img {
        margin: 0 auto;
    }

    hr{
        max-width: 100%;
    }
}