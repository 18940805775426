header{
    height: 100vh;
    overflow: hidden;
}

.header-container{
    text-align: center;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
}

/* socials */

a.svg{
    color: var(--color-white);
}

a.svg:hover{
    color: var(--color-primary);
}

.header-socials{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

/* picture */

.me > img{
    width: 17rem;
    height: 17rem;
    border-radius: 50%;
    border: 0.5rem solid var(--color-primary);
    box-shadow: 0 0 0.5rem var(--color-primary);
    margin: 0 auto;
    display: block;
}

/* scroll button */

.scroll{
    padding: 1rem !important;
    border-radius: 50%;
    margin-top: 2rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: scroll 1s infinite alternate;
}

/* media queries */

@media screen and (max-width: 1000px){
    header {
        height: fit-content;
    }
}

@media screen and (max-width: 600px){
    header{
        height: 100vh;
    }
}