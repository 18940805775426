.projects-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
}

#projects > h2{
    text-align: center;
}

.project{
    background: transparent;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.project:hover{
    border: 1px solid var(--color-primary);
    opacity: 0.8;
    transform: scale(1.02);
}

.project-image {
    border-radius: 1rem;
    overflow: hidden;
}

.project-image img{
    height: 100%;
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.project h3{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.project-tech{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: -0.5rem;
}

li{
    border-radius: 0.3rem;
    background: var(--color-primary);
    padding: 0.2rem 0.5rem;
    font-size: 0.7rem;
    color: var(--color-bg);
}

a{
    margin-right: 0.5rem;
}

/* media queries */

@media screen and (max-width: 1000px){
    .projects-container{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px){
    .projects-container{
        grid-template-columns: repeat(1, 1fr);
    }
}