#experience > h2 {
    text-align: center;   
}

.experience-container{
    display: flex;
}

.timeline-heading > h2 {
    margin-bottom: 0;
}

.timeline-panel{
    width: clamp(15rem, 30%, 20rem);
}

.work{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    position: relative;
    left: calc(50% - 2rem);
}

.work-inverted{
    display: flex;
    align-items: flex-start;
    text-align: right;
    gap: 1rem;
    flex-direction: row-reverse;
    position: relative;
    right: 51%;
}

.timeline{
    position: relative;
    padding: 0;
    overflow: hidden;
}

.timeline::before{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    right: 50%;
    content: '';
    background-color: var(--color-white);
    z-index: -1;
}

.image{
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    background: black;
    border-radius: 50%;
    border: 1px solid var(--color-primary);
}

/* media queries */

@media screen and (max-width: 700px){
    .timeline-heading > h2 {
        font-size: 1.2rem;
    }

    .timeline-panel{
        width: 35%;
    }
}