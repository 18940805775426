nav {
    background: rgba(0, 0, 0, 0.4);
    width: max-content;
    display: block;
    padding: 0.7rem 1.2rem;
    z-index: 10;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 0.1rem;
    border-radius: 3rem;
    backdrop-filter: blur(1rem);
}

/* icons */

nav > a{
    display: flex;
    color: var(--color-light);
    background: transparent;
    padding: 0.7rem;
    border-radius: 50%;
    font-size: 1.3rem;
    z-index: 5;
}

nav > a:hover{
    background: rgba(255, 255, 255, 0.2);
}

nav > a.currentview {
    background: var(--color-bg);
    color: var(--color-white);
}